import axios from "axios";
import { BACKEND_API_URL } from '../constants'

const register = (username, email, password) => {
  return axios.post(BACKEND_API_URL + "register", {
    username,
    email,
    password,
  });
};

const resendEmailConfirmation = (token) => {
  return axios.post(BACKEND_API_URL + "resend_confirmation", {
    token
  });
};

const login = (email, password) => {
  return axios
    .post(BACKEND_API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if(response.data.success)
      {
        //console.log("Succesfull log in");
        // store token in cookie
        //setTokenCookie(response.data.expiresIn, response.data.token);
      }
      else
      {
        //throw
        //console.log(response.data.message);
        throw new Error("Not authorized");
      }
      if (response.data.token)
      {
        response.data.expirationTime = new Date().getTime() + response.data.expiresIn*60*1000;
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const isLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let nowTime = new Date().getTime();
  if (user && user.token) {
    if (nowTime >= user.expirationTime)
    {
      logout();
      return false;
    }
    return true;
  } else {
    return false;
  }
};

const isUserConfirmed = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.confirmed)
    return user.confirmed;
  return false;
};


const allFunctionCalls = {
  register,
  resendEmailConfirmation,
  login,
  logout,
  getCurrentUser,
  isLoggedIn,
  isUserConfirmed
};

export default allFunctionCalls;
