/*export const lightTheme = {
    bg_1: "white",
    bg_2: "#6c757d",
    bg_3: "#6c757d",
    bg_4: "#6c757d",
    color_1: "#343a40",
    color_2: "#0000aa",
    color_3: "#000077",
    color_4: "#000077",
    text_1: "white",
    text_2: "white",
    text_3: "white",
    text_4: "white"
};
*/

export const theme = {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryHover: '#343078',
    mobile: '576px',
    primaryBackground: 'rgb(44, 73, 83)',
    secondaryBackground: 'rgb(245, 237, 219)',
    primaryFontColor: 'rgb(0,0,0)',
    primaryFontColorHover: 'rgb(44, 73, 83)',
    secondaryFontColor: 'rgb(255,255,255)',
    secondaryFontColorHover: 'rgb(255,255,255)',
    primaryColor: 'rgb(255, 168, 128)',
    secondaryColor: 'rgb(179, 242, 186)',
  }
