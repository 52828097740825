import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu, HeaderFont } from './Menu.styled';


import { NavLink as Link } from "react-router-dom";
import { ENDPOINT_HOME, ENDPOINT_PRODUCTS, ENDPOINT_CONTACTUS, ENDPOINT_DASHBOARD, ENDPOINT_PROFILE, ENDPOINT_LOGIN, ENDPOINT_REGISTER, ENDPOINT_CERTIFICATES } from '../../constants';
import { useTranslation } from 'react-i18next';

const Menu = ({ open, ...props }) => {
  const { t } = useTranslation("common");
  const { currentUser, logOutHandler, setOpen } = props;

  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const closeMenu = () => setOpen(false);
  const logOutAndClose = () => {
    logOutHandler();
    closeMenu();
  }

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
        <HeaderFont>
          {t("txt.ProductName")}
        </HeaderFont>
        <Link to={ENDPOINT_HOME} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
          {t("txt.Home")}
        </Link>
        <Link to={ENDPOINT_PRODUCTS} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
          {t("txt.Products")}
        </Link>
        <Link to={ENDPOINT_CONTACTUS} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
          {t("txt.ContactUs")}
        </Link>
        {currentUser && (
            <Link to={ENDPOINT_DASHBOARD} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
              {t("txt.Dashboard")}
            </Link>
        )}
        {currentUser && (
          <Link to={ENDPOINT_CERTIFICATES} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
            {t("txt.CertificateAdministration")}
          </Link>
        )}
         {currentUser ? (
            <>
                <Link to={ENDPOINT_PROFILE} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
                  {currentUser.username}
                </Link>
                <a href={ENDPOINT_LOGIN} className="nav-link" onClick={logOutAndClose} tabIndex={tabIndex}>
                  {t("txt.LogOut")}
                </a>
            </>
          ) : (
            <>
              <Link to={ENDPOINT_LOGIN} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
                {t("txt.LogIn")}
              </Link>
              <Link to={ENDPOINT_REGISTER} className="nav-link" tabIndex={tabIndex} onClick={closeMenu}>
                {t("txt.Register")}
              </Link>
            </>
          )}
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;