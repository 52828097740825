import React, { useState, useEffect, Suspense, useRef } from "react";

import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import AuthService from "./services/auth.service";

import styled, {keyframes} from 'styled-components';

import { ENDPOINT_HOME, ENDPOINT_PRODUCTS, ENDPOINT_CONTACTUS, ENDPOINT_DASHBOARD, ENDPOINT_PROFILE, ENDPOINT_LOGIN, ENDPOINT_REGISTER, ENDPOINT_CERTIFICATES, ENDPOINT_SUCCESS, ENDPOINT_CONFIRM, ENDPOINT_FAIL } from './constants';
import { useOnClickOutside } from './hooks';
import { Burger, Menu } from './components';
import FocusLock from 'react-focus-lock';

const Login = React.lazy( () => import('./components/Login'));
const Register = React.lazy( () => import('./components/Register'));
const Home = React.lazy( () => import('./components/Home'));
const Products = React.lazy( () => import('./components/Products'));
const ContactUs = React.lazy( () => import('./components/ContactUs'));
const Profile = React.lazy( () => import('./components/Profile'));
const Dashboard = React.lazy( () => import('./components/Dashboard'));
const NotFound = React.lazy( () => import('./components/NotFound'));
const Footer = React.lazy( () => import('./components/Footer'));
const CertificatesAdministration = React.lazy( () => import('./components/Certificates'));
const MessageSuccess = React.lazy( () => import('./components/MessageSuccess'));
const MessageFail = React.lazy( () => import('./components/MessageFail'));
const ConfirmEmailPage = React.lazy( () => import('./components/ConfirmEmailPage'));

const FullContainer = styled.div`
  height:100vh;
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
min-height: 100vh;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const MainContainer = styled.div`
  flex: 1;
  padding: 0px;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  max-width: 768px;
`;

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      if (!AuthService.isLoggedIn())
      {
        setCurrentUser(null);
      }
    }
  }, []);


  const logOutHandler = () => {
    AuthService.logout();
  };


  return (
    <>
      <FullContainer>
        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} currentUser={currentUser} logOutHandler={logOutHandler} />
          </FocusLock>
        </div>
        <MainContainer>
          <Suspense 
            fallback=
              {
                <SpinnerContainer>
                  <Spinner/>
                  <span>Loading...</span>
                </SpinnerContainer>
              }
            >
            <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path={ENDPOINT_HOME} element={<Home/>} />
              <Route exact path={ENDPOINT_PRODUCTS} element={<Products/>} />
              <Route exact path={ENDPOINT_CONTACTUS} element={<ContactUs/>} />
              <Route exact path={ENDPOINT_LOGIN} element={<Login/>} />
              <Route exact path={ENDPOINT_REGISTER} element={<Register/>} />
              <Route exact path={ENDPOINT_PROFILE} element={<Profile/>} />
              <Route exact path={ENDPOINT_DASHBOARD} element={<Dashboard/>} />
              <Route exact path={ENDPOINT_CERTIFICATES} element={<CertificatesAdministration/>} />
              <Route exact path={ENDPOINT_SUCCESS} element={<MessageSuccess/>} />
              <Route exact path={ENDPOINT_FAIL} element={<MessageFail/>} />
              <Route path={ENDPOINT_CONFIRM+'/:token'} exact element={<ConfirmEmailPage/>} />
              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </MainContainer>
        <Footer />
      </FullContainer>
    </>
  );
};

export default App;
