export const MQTT_URL = "www.0wattcp.com";
export const MQTT_PORT = 8883;
export const MQTT_PORT_EC = 8885;

const API_DOMAIN_NAME=window.location.hostname;

export const WEBSOCKET_URL = "wss://0wattcp.com/socket.io/";
//export const WEBSOCKET_URL = "ws://localhost:10458";

export const UAM_URL = "wss://0wattcp.com/uam/";
//export const UAM_URL = "ws://localhost:10500";

export const BACKEND_API_URL = "https://"+API_DOMAIN_NAME+"/api/";
//export const BACKEND_API_URL = "https://www.0wattcp.com/api/";
//export const BACKEND_API_URL = "http://localhost:5000/api/";

export const ENDPOINT_HOME = '/home';
export const ENDPOINT_PRODUCTS = '/products';
export const ENDPOINT_CONTACTUS = '/contact-us';
export const ENDPOINT_LOGIN = '/login';
export const ENDPOINT_REGISTER = '/register';
export const ENDPOINT_PROFILE = '/profile';
export const ENDPOINT_DASHBOARD = '/dashboard';
export const ENDPOINT_CERTIFICATES = '/certificates';
export const ENDPOINT_SUCCESS = '/success';
export const ENDPOINT_FAIL = '/failed';
export const ENDPOINT_CONFIRM = '/confirm';

export const WEBUI_VERSION = "v0.7.3.2";

