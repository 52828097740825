import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';

import App from "./App";
import './i18n';

import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './global';
import { theme } from './theme';

import { ToastContainer, Flip } from 'react-toastify';

import styled, {keyframes} from 'styled-components';

const root = ReactDOM.createRoot(document.getElementById("root"));


const FullContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
min-height: 100vh;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

root.render(
  <>
    <BrowserRouter>
      <ToastContainer position="bottom-right"
                          autoClose={2000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="dark"
                          transition={Flip}
                          />
      <Suspense fallback=
        {
          <FullContainer>
            <Spinner/>
            <span>Loading...</span>
          </FullContainer>
        }
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  </>
);
